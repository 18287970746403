import React from 'react';
import ReactDOM from 'react-dom';
import { fullDate } from '../utils/dataManipulation';

class Portal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.containerDiv = document.createElement('div'); // STEP 1: create an empty div
		this.printWindow = null;
	}

	copyStyles = (sourceDoc, targetDoc) => {
		let cssArray = Array.from(sourceDoc.styleSheets);
		cssArray.forEach(styleSheet => {
			if (!styleSheet.ownerNode.outerHTML.includes('google')) {
				const newStyleEl = sourceDoc.createElement('style');
				Array.from(styleSheet.cssRules).forEach(cssRule => {
					newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
				});
				targetDoc.head.appendChild(newStyleEl);
			}
		});
	}

	componentDidMount() {
		// STEP 3: open a new browser window and store a reference to it
		this.printWindow = window.open('', '', 'width=1000,height=600,left=50,top=50');

		// STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
		this.printWindow.document.body.appendChild(this.containerDiv);

		this.printWindow.document.title =
			'Izveštaj - ' +
			this.props.appointment.patientfirstname +
			' ' +
			this.props.appointment.patientlastname +
			' - ' +
			fullDate(this.props.appointment.date);

		this.copyStyles(document, this.printWindow.document);

		setTimeout(() => {
			this.printWindow.print();
			this.props.closeWindowPortal();
		}, 300);
	}

	componentWillUnmount() {
		// This will fire when this.state.showWindowPortal in the parent component becomes false
		// So we tidy up by just closing the window
		this.printWindow.close();
	}

	render() {
		// STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
		return ReactDOM.createPortal(this.props.children, this.containerDiv);
	}
}
export default Portal;
