import React, { Component } from 'react';
import Notification from '../components/notification';
import { logout } from '../utils';
import { inputValidation, formValidation } from '../utils/inputValidation';
import { SelectItem, Input, Button } from './FormComponents';
import loader from '../ImgSrc/loader.gif';

class AddPerson extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newDoctor: {
				id: '',
				firstname: '',
				lastname: '',
				birthdate: '',
				street: '',
				streetnum: '',
				city: '',
				country: '',
				email: '',
				phone: '',
				sex: '',
				title: '',
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			newPatient: {
				id: '',
				firstname: '',
				lastname: '',
				birthdate: '',
				street: '',
				streetnum: '',
				city: '',
				country: '',
				email: '',
				phone: '',
				sex: '',
				profession: '',
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			personId: '',
			sexOptions: [
				{ name: 'Muški', id: 1 },
				{ name: 'Ženski', id: 2 }
			],
			left: -400,
			notificationMsg: '',
			isPrimBtnDisabled: false,
			secBtnClass: 'sec-btn hdn'
		};
		this.timeout = null;
		this.form = React.createRef();
	}

	handleInput = e => {
		let value = e.target.value.trimStart();
		let name = e.target.name;
		if (this.props.doctorProfile) {
			this.setState(prevState => ({
				newDoctor: {
					...prevState.newDoctor,
					[name]: value
				},
				secBtnClass: 'sec-btn hdn'
			}));
		} else if (this.props.patientProfile) {
			this.setState(prevState => ({
				newPatient: {
					...prevState.newPatient,
					[name]: value
				},
				secBtnClass: 'sec-btn hdn'
			}));
		}
		inputValidation(e);
	};

	userData = () => {
		return this.props.doctorProfile ? this.state.newDoctor : this.state.newPatient;
	};

	handleFormSubmit = e => {
		e.preventDefault();
		if (formValidation(this.form.current)) {
			this.setState({
				isPrimBtnDisabled: true
			});
			fetch(this.props.url, {
				method: 'POST',
				body: JSON.stringify(this.userData()),
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			}).then(response => {
				response.json().then(data => {
					if (response.status === 200) {
						this.props.doctorProfile
							? this.props.history.push('/dashboard/doctor/' + data)
							: this.props.history.push('/dashboard/patient/' + data);

						/** Mogućnost da se korisniku da opcija da bira hoće li da ode na profil osobe bez automatskog slanja  **/
						// this.setState({
						// 	notificationMsg: this.props.doctorProfile
						// 		? 'Uspešno ste upisali novog doktora sa ID-jem: ' + data
						// 		: 'Uspešno ste upisali novog pacijenta sa ID-jem: ' + data,
						// 	isPrimBtnDisabled: false,
						// 	personId: data,
						// 	secBtnClass: 'sec-btn'
						// });
						// this.handleClearForm(e);
						/**********************************************************************************************************/
					} else if (response.status === 400 || response.status === 500) {
						if (data.error.message.includes('ER_DUP_ENTRY')) {
							this.setState({
								notificationMsg: 'Osoba sa ovim podacima je već upisana!',
								isPrimBtnDisabled: false
							});
						} else {
							this.setState({
								notificationMsg: 'Greška! Problem na serveru',
								isPrimBtnDisabled: false
							});
						}
						this.onShowNotification();
					} else if (response.status === 401) {
						logout();
						this.props.history.push('/signin');
						return;
					}
				});
			});
		}
	};

	/** Mogućnost da se korisniku da opcija da bira hoće li da ode na profil osobe bez automatskog slanja  **/
	// handleClearForm = e => {
	// 	e.persist();
	// 	if (this.props.doctorProfile) {
	// 		this.setState({
	// 			newDoctor: {
	// 				id: '',
	// 				firstname: '',
	// 				lastname: '',
	// 				birthdate: '',
	// 				street: '',
	// 				streetnum: '',
	// 				city: '',
	// 				country: '',
	// 				email: '',
	// 				phone: '',
	// 				sex: '',
	// 				title: '',
	// 				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
	// 			}
	// 		});
	// 	} else if (this.props.patientProfile) {
	// 		this.setState({
	// 			newPatient: {
	// 				id: '',
	// 				firstname: '',
	// 				lastname: '',
	// 				birthdate: '',
	// 				street: '',
	// 				streetnum: '',
	// 				city: '',
	// 				country: '',
	// 				email: '',
	// 				phone: '',
	// 				sex: '',
	// 				profession: '',
	// 				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
	// 			}
	// 		});
	// 	}
	// };
	/**********************************************************************************************************/

	onShowNotification = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.setState({ left: -400 }, () => {
				this.timeout = setTimeout(() => {
					this.showNotification();
				}, 500);
			});
		} else {
			this.showNotification();
		}
	};

	showNotification = () => {
		this.setState(
			{
				left: 20
			},
			() => {
				this.timeout = setTimeout(() => {
					this.setState({
						left: -400
					});
				}, 3000);
			}
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	};

	/** Mogućnost da se korisniku da opcija da bira hoće li da ode na profil osobe bez automatskog slanja  **/
	// openProfile = e => {
	// 	e.preventDefault();
	// 	this.props.doctorProfile
	// 		? this.props.history.push('/dashboard/doctor/' + this.state.personId)
	// 		: this.props.history.push('/dashboard/patient/' + this.state.personId);
	// };
	/**********************************************************************************************************/

	cancelSubmit = e => {
		e.preventDefault();
		this.props.doctorProfile
			? this.props.history.push('/dashboard/doctors')
			: this.props.history.push('/dashboard/patients');
	};

	render() {
		return (
			<div className='form_div'>
				<form ref={this.form} className='form-container' onSubmit={this.handleFormSubmit}>
					{this.props.doctorProfile ? (
						<div className='add-doctor naslov'>
							<i className='fas fa-user-md'></i> Upis novog doktora
						</div>
					) : (
						<div className='add-patient naslov'>
							<i className='fas fa-user'></i> Upis novog pacijenta
						</div>
					)}
					<Input
						type={'text'}
						title={'Ime'}
						name={'firstname'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.firstname
								: this.state.newPatient.firstname
						}
						placeholder={'Unesite ime'}
						onChange={this.handleInput}
						onBlur={inputValidation}
						disabled={this.state.isPrimBtnDisabled}
						required
					/>
					<Input
						type={'text'}
						name={'lastname'}
						title={'Prezime'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.lastname
								: this.state.newPatient.lastname
						}
						placeholder={'Unesite prezime'}
						onChange={this.handleInput}
						onBlur={inputValidation}
						disabled={this.state.isPrimBtnDisabled}
						required
					/>
					<Input
						type={'date'}
						name={'birthdate'}
						title={'Datum rođenja'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.birthdate
								: this.state.newPatient.birthdate
						}
						placeholder={'Unesite datum rođenja'}
						disabled={this.state.isPrimBtnDisabled}
						onChange={this.handleInput}
						onBlur={inputValidation}
						required={this.props.doctorProfile ? false : true}
					/>
					<SelectItem
						title={'Pol'}
						name={'sex'}
						options={this.state.sexOptions}
						value={this.props.doctorProfile ? this.state.newDoctor.sex : this.state.newPatient.sex}
						placeholder={'Odaberite pol'}
						disabled={this.state.isPrimBtnDisabled}
						onChange={this.handleInput}
						onBlur={inputValidation}
						required={this.props.doctorProfile ? false : true}
					/>
					<Input
						type={'text'}
						title={'Ulica'}
						name={'street'}
						value={
							this.props.doctorProfile ? this.state.newDoctor.street : this.state.newPatient.street
						}
						placeholder={'Unesite ulicu'}
						onChange={this.handleInput}
						disabled={this.state.isPrimBtnDisabled}
					/>
					<Input
						type={'text'}
						name={'streetnum'}
						title={'Broj'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.streetnum
								: this.state.newPatient.streetnum
						}
						placeholder={'Unesite broj'}
						onChange={this.handleInput}
						disabled={this.state.isPrimBtnDisabled}
					/>
					<Input
						type={'text'}
						name={'city'}
						title={'Grad'}
						value={
							this.props.doctorProfile ? this.state.newDoctor.city : this.state.newPatient.city
						}
						placeholder={'Unesite grad'}
						onChange={this.handleInput}
						disabled={this.state.isPrimBtnDisabled}
					/>
					<Input
						type={'text'}
						name={'country'}
						title={'Država'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.country
								: this.state.newPatient.country
						}
						placeholder={'Unesite državu'}
						onChange={this.handleInput}
						disabled={this.state.isPrimBtnDisabled}
					/>
					<Input
						type={'email'}
						name={'email'}
						title={'Email'}
						value={
							this.props.doctorProfile ? this.state.newDoctor.email : this.state.newPatient.email
						}
						placeholder={'Unesite email'}
						onChange={this.handleInput}
						onBlur={inputValidation}
						disabled={this.state.isPrimBtnDisabled}
						required
					/>
					<Input
						type={'text'}
						name={'phone'}
						title={'Telefon'}
						value={
							this.props.doctorProfile ? this.state.newDoctor.phone : this.state.newPatient.phone
						}
						placeholder={'Unesite telefon'}
						onChange={this.handleInput}
						onBlur={inputValidation}
						disabled={this.state.isPrimBtnDisabled}
						required
					/>
					<Input
						type={'text'}
						name={this.props.doctorProfile ? 'title' : 'profession'}
						title={this.props.doctorProfile ? 'Zvanje' : 'Profesija'}
						value={
							this.props.doctorProfile
								? this.state.newDoctor.title
								: this.state.newPatient.profession
						}
						placeholder={this.props.doctorProfile ? 'Unesite zvanje' : 'Unesite profesiju'}
						onChange={this.handleInput}
						disabled={this.state.isPrimBtnDisabled}
					/>
					<div className='add-person-btn_div'>
						<div className={this.state.isPrimBtnDisabled ? 'loader-btn_div' : 'loader-btn_div hdn'}>
							<img src={loader} alt='Učitava se...'></img>
						</div>
						<div className={this.state.isPrimBtnDisabled ? 'btn_div hdn' : 'btn_div'}>
							<Button
								action={this.handleFormSubmit}
								type={'primary'}
								disabled={this.state.isPrimBtnDisabled}
								title='Sačuvaj'
							/>
							<Button
								action={this.cancelSubmit}
								disabled={this.state.isPrimBtnDisabled}
								title='Odustani'
							/>
						</div>
					</div>
					{/* Mogućnost da se korisniku da opcija da bira hoće li da ode na profil osobe bez automatskog slanja  */}
					{/* <div className={this.state.secBtnClass}>
						<Button
							action={this.openProfile}
							title={this.props.doctorProfile ? 'Idi na profil doktora' : 'Idi na profil pacijenta'}
						/>
					</div> */}
					{/**********************************************************************************************************/}
				</form>
				<Notification left={this.state.left} notificationMsg={this.state.notificationMsg} />
			</div>
		);
	};
}

export default AddPerson;
