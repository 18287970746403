import React, { Component } from 'react';
import Notification from '../components/notification';
import { logout, host } from '../utils';
import { inputValidation, formValidation } from '../utils/inputValidation';
import { formDate } from '../utils/dataManipulation';
import { SelectPerson, Input, TextArea, CheckBox, Button, SelectLocation } from './FormComponents';
import loader from '../ImgSrc/loader.gif';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateType,
	FilePondPluginImageTransform
);

class AddAppointmentForm extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			newAppointment: {
				patientid: this.props.match.params.id,
				doctorid:
					localStorage.getItem('doctor_id') === 'null' ? '' : localStorage.getItem('doctor_id'),
				locationid: '',
				date: formDate(),
				anamnesis: '',
				obj_findings: '',
				diagnosis: '',
				therapy: '',
				advice: '',
				treatments: [],
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			patientFullname: '',
			doctorLoggedIn: null,
			doctorOptions: [],
			locationOptions: [],
			treatmentOptions: [],
			files: [],
			filesUploaded: 0,
			appointmentid: '',
			filePondClass: 'form-container images hidden',
			checkboxClass: 'checkbox',
			checkboxRequired: true,
			left: -500,
			notificationMsg: '',
			isPrimBtnDisabled: false,
			isSecBtnDisabled: false,
			isInputDisabled: false,
			loading: true,
			loaderClass: 'loader-btn_div hdn'
		};
		this.timeout = null;
		this.form = React.createRef();
		this.pond = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		let pUrl = host + 'v1/patients/' + this.props.match.params.id;
		let dUrl = host + 'v1/doctors/';
		let lUrl = host + 'v1/locations/';
		(async () => {
			let response = await fetch(pUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						patientFullname: data[0].firstname + ' ' + data[0].lastname,
						loading: false
					});
				}
			}
		})();
		(async () => {
			let response = await fetch(dUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						doctorOptions: data,
						loading: false
					});
					data.forEach(doctor => {
						if (doctor.id.toString() === localStorage.getItem('doctor_id')) {
							this.setState({
								doctorLoggedIn: doctor
							});
						}
					});
				}
			}
		})();
		(async () => {
			let response = await fetch(lUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						locationOptions: data,
						treatmentOptions: data[0].treatments
					});
				}
			}
		})();
	}

	handleInput = e => {
		let value = e.target.value;
		let name = e.target.name;
		this.setState(
			prevState => ({
				newAppointment: {
					...prevState.newAppointment,
					[name]: value
				}
			})
			// () => console.log(this.state.newAppointment)
		);
		inputValidation(e);
	};

	handleCheckBox = e => {
		const newSelection = e.target.value;
		let newSelectionArray;

		if (this.state.newAppointment.treatments.indexOf(newSelection) > -1) {
			newSelectionArray = this.state.newAppointment.treatments.filter(s => s !== newSelection);
		} else {
			newSelectionArray = [...this.state.newAppointment.treatments, newSelection];
		}

		if (newSelectionArray.length !== 0) {
			this.setState({
				checkboxClass: 'checkbox check',
				checkboxRequired: false
			});
		} else {
			this.setState({
				checkboxClass: 'checkbox',
				checkboxRequired: true
			});
		}

		this.setState(
			prevState => ({
				newAppointment: { ...prevState.newAppointment, treatments: newSelectionArray }
			})
			// () => console.log(this.state.newAppointment)
		);
	};

	handleFormSubmit = e => {
		e.preventDefault();
		if (formValidation(this.form.current)) {
			this.setState({
				isPrimBtnDisabled: true,
				loaderClass: 'loader-btn_div'
			});
			let userData = this.state.newAppointment;
			let url = host + 'v1/appointments';
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(userData),
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			}).then(response => {
				response.json().then(data => {
					if (response.status === 200) {
						this.setState({
							notificationMsg: 'Uspešno ste sačuvali podatke. Ako želite dodajte slike.',
							isInputDisabled: true,
							filePondClass: 'form-container images',
							loaderClass: 'loader-btn_div hdn',
							appointmentid: data
						});
					} else if (response.status === 400 || response.status === 500) {
						this.setState({
							notificationMsg: 'Greška! Problem na serveru',
							isPrimBtnDisabled: false,
							loaderClass: 'loader-btn_div hdn'
						});
					} else if (response.status === 401) {
						logout();
						this.props.history.push('/signin');
						return;
					}
					this.onShowNotification();
				});
			});
		} else if (this.state.newAppointment.treatments.length === 0) {
			this.setState({
				checkboxClass: 'checkbox mustcheck'
			});
		}
	};

	handleClearForm = e => {
		e.persist();
		this.setState({
			newAppointment: {
				patientid: '',
				doctorid: '',
				locationid: '',
				date: formDate(),
				anamnesis: '',
				obj_findings: '',
				diagnosis: '',
				therapy: '',
				advice: '',
				treatments: [],
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			}
		});
	};

	onShowNotification = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.setState({ left: -500 }, () => {
				this.timeout = setTimeout(() => {
					this.showNotification();
				}, 500);
			});
		} else {
			this.showNotification();
		}
	};

	showNotification = () => {
		this.setState(
			{
				left: 20
			},
			() => {
				this.timeout = setTimeout(() => {
					this.setState({
						left: -500
					});
				}, 3000);
			}
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
		this._isMounted = false;
	}

	cancelSubmit = e => {
		e.preventDefault();
		this.props.history.push('/dashboard/patient/' + this.state.newAppointment.patientid);
	};

	handleInit = () => {
		console.log('FilePond instance has initialised', this.pond);
	};

	onSuccess = e => {
		this.setState({
			files: [],
			isInputDisabled: false,
			isPrimBtnDisabled: false,
			filePondClass: 'form-container images hidden'
		});
		this.handleClearForm(e);
		this.props.history.push('/dashboard/patient/' + this.state.newAppointment.patientid);
	};

	render() {
		if (this.state.loading) {
			return (
				<div className='loader_div'>
					<img src={loader} alt='Učitava se...'></img>
				</div>
			);
		} else {
			return (
				<div className='appointment_div'>
					<form ref={this.form} className='form-container report' onSubmit={this.handleFormSubmit}>
						<div className='add-appointment naslov'>Novi izveštaj</div>
						<Input
							type={'text'}
							title={'Pacijent'}
							name={'name'}
							value={this.state.patientFullname}
							disabled
							required
						/>
						<SelectPerson
							user={this.state.doctorLoggedIn}
							title={'Doktor'}
							name={'doctorid'}
							options={this.state.doctorOptions}
							value={this.state.newAppointment.doctorid}
							placeholder={'Odaberi doktora'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							required
							disabled={this.state.isInputDisabled}
						/>
						<SelectLocation
							title={'Lokacija'}
							name={'locationid'}
							options={this.state.locationOptions}
							value={this.state.newAppointment.locationid}
							placeholder={'Odaberi lokaciju'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							required
							disabled={this.state.isInputDisabled}
						/>
						<Input
							type={'date'}
							name={'date'}
							title={'Datum pregleda'}
							value={this.state.newAppointment.date}
							onChange={this.handleInput}
							onBlur={inputValidation}
							required
							disabled={this.state.isInputDisabled}
						/>
						<CheckBox
							className={this.state.checkboxClass}
							title={'Tretmani'}
							name={'treatments'}
							options={this.state.treatmentOptions}
							selectedOptions={this.state.newAppointment.treatments}
							onChange={this.handleCheckBox}
							disabled={this.state.isInputDisabled}
							required={this.state.checkboxRequired}
						/>
						<TextArea
							title={'Anamneza'}
							rows={3}
							value={this.state.newAppointment.anamnesis}
							name={'anamnesis'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Objektivni nalaz'}
							rows={3}
							value={this.state.newAppointment.obj_findings}
							name={'obj_findings'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Dijagnoza'}
							rows={3}
							value={this.state.newAppointment.diagnosis}
							name={'diagnosis'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Terapija'}
							rows={3}
							value={this.state.newAppointment.therapy}
							name={'therapy'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Savet i kontrola'}
							rows={3}
							value={this.state.newAppointment.advice}
							name={'advice'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<div className={this.state.isPrimBtnDisabled ? 'btn_div hdn' : 'btn_div'}>
							<Button
								action={this.handleFormSubmit}
								disabled={this.state.isPrimBtnDisabled}
								title='Sačuvaj'
							/>
							<Button
								action={this.cancelSubmit}
								disabled={this.state.isPrimBtnDisabled}
								title='Odustani'
							/>
						</div>
						<div className={this.state.loaderClass}>
							<img src={loader} alt='Učitava se...'></img>
						</div>
					</form>
					<div className={this.state.filePondClass}>
						<FilePond
							ref={ref => (this.pond = ref)}
							files={this.state.files}
							allowMultiple={true}
							allowRevert={false}
							maxFiles={10 - this.state.filesUploaded}
							dropOnPage={true}
							instantUpload={false}
							imagePreviewHeight='170'
							acceptedFileTypes={['image/jpeg']}
							labelIdle='<span class="filepond--label-action">Ubacite slike</span>'
							labelTapToCancel=''
							labelTapToRetry=''
							onupdatefiles={fileItems => {
								this.setState({
									files: fileItems.map(fileItem => fileItem.file)
								});
								if (this.state.files.length > 0) {
									this.setState({
										isSecBtnDisabled: true
									});
								} else {
									this.setState({
										isSecBtnDisabled: false
									});
								}
							}}
							onprocessfile={(error, file) => {
								if (!error) {
									this.setState({ filesUploaded: this.state.filesUploaded + 1 });
									// console.log(this.state.filesUploaded);
									setTimeout(() => {
										this.pond.removeFile(file);
									}, 300);
								}
							}}
							// oninit={() => this.handleInit()}

							server={{
								process: (fieldName, file, metadata, load, error, progress, abort) => {
									// fieldName is the name of the input field
									// file is the actual file object to send
									const formData = new FormData();
									formData.append('appointmentid', this.state.appointmentid);
									formData.append('patientid', this.state.newAppointment.patientid);
									formData.append(
										'updated_by',
										localStorage.getItem('user') ? localStorage.getItem('user') : ''
									);
									formData.append('images', file, file.name);

									// for (var pair of formData.entries()) {
									// 	console.log(pair[0] + ', ' + pair[1]);
									// }
									const request = new XMLHttpRequest();
									// console.log(request);
									request.open('POST', host + 'v1/images');
									// Should call the progress method to update the progress to 100% before calling load
									// Setting computable to false switches the loading indicator to infinite mode
									request.upload.onprogress = e => {
										progress(e.lengthComputable, e.loaded, e.total);
									};
									// Should call the load method when done and pass the returned server file id
									// this server file id is then used later on when reverting or restoring a file
									// so your server knows which file to return without exposing that info to the client
									request.onload = () => {
										if (request.status >= 200 && request.status < 300) {
											// the load method accepts either a string (id) or an object
											load(request.responseText);
											// console.log(JSON.parse(request.response));
										} else {
											// Can call the error method if something is wrong, should exit after
											error('oh no');
										}
									};
									request.send(formData);

									// Should expose an abort method so the request can be cancelled
									// return {
									// 	abort: () => {
									// 		// This function is entered if the user has tapped the cancel button
									// 		request.abort();
									// 		// Let FilePond know the request has been cancelled
									// 		abort();
									// 	}
									// };
								}
							}}
						/>
						<Button
							action={this.onSuccess}
							type={'secondary'}
							disabled={this.state.isSecBtnDisabled}
							title={
								this.state.isSecBtnDisabled
									? 'Uploadujte slike klikom na strelicu ili ih obrišite klikom na x'
									: 'Gotovo'
							}
						/>
					</div>
					<Notification left={this.state.left} notificationMsg={this.state.notificationMsg} />
				</div>
			);
		}
	}
}

export default AddAppointmentForm;
