const TOKEN_KEY = 'token';
const USER = 'user';
const DOCTOR_ID = 'doctor_id';

export const login = (userValue, tokenValue, doctorIdValue) => {
	localStorage.setItem(TOKEN_KEY, tokenValue);
	localStorage.setItem(USER, userValue);
	localStorage.setItem(DOCTOR_ID, doctorIdValue);
};

export const logout = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(USER);
	localStorage.removeItem(DOCTOR_ID);
	clearLocalStr();
};

export const isLogin = () => {
	if (localStorage.getItem(TOKEN_KEY)) {
		return true;
	}
	return false;
};

export const clearLocalStr = () => {
	localStorage.removeItem('appo_id');
	localStorage.removeItem('profile');
};

export const host = process.env.REACT_APP_HOST_URL;
// export const host = 'https://lekarska-rest-api.herokuapp.com/';
