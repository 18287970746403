import React from 'react';

import Header from './header';
import Navbar from './navbar';
import Content from './content';

const Dashboard = props => {
	return (
		<div className='dashboard'>
			<Header />
			<Navbar props={props} />
			<Content />
		</div>
	);
};

export default Dashboard;
