import React from 'react';
import './App.css';
import { Switch } from 'react-router-dom';

import SignUp from './Forms/SignUp';
import SignIn from './Forms/SignIn';
import Dashboard from './components/dashboard';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

function App() {
	return (
		<div className='App'>
			<Switch>
				<PublicRoute restricted={true} component={SignUp} path='/signup' exact />
				<PublicRoute restricted={true} component={SignIn} path='/signin' exact />
				<PublicRoute restricted={true} component={SignIn} path='/' exact />
				<PrivateRoute component={Dashboard} path='/dashboard' />
			</Switch>
		</div>
	);
}

export default App;
