export const inputValidation = e => {
	let input = e.target;

	if (input.name === 'treatments' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas odaberite bar jedan od ponuđenih tretmana');
	} else if (input.name === 'treatments' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'sex' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas odaberite pol');
	} else if (input.name === 'sex' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'locationid' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas odaberite lokaciju');
	} else if (input.name === 'locationid' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'doctorid' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas odaberite doktora');
	} else if (input.name === 'doctorid' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'name' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas unesite ime i prezime');
	} else if (input.name === 'name' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'firstname' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas unesite ime');
	} else if (input.name === 'firstname' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'lastname' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas unesite prezime');
	} else if (input.name === 'lastname' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'phone' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas unesite telefon');
	} else if (input.name === 'phone' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'password' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas unesite lozinku');
	} else if (input.name === 'password' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.name === 'confirmPassword' && input.validity.valueMissing) {
		input.setCustomValidity('Molimo Vas ponovite lozinku');
	} else if (input.name === 'confirmPassword' && !input.validity.valueMissing) {
		input.setCustomValidity('');
	}

	if (input.type === 'date' && input.validity.valueMissing && !input.validity.badInput) {
		input.setCustomValidity('Molimo Vas unesite datum');
	} else if (input.type === 'date' && input.validity.valueMissing && input.validity.badInput) {
		input.setCustomValidity('Unesite ispravan datum');
	} else if (input.type === 'date' && !input.validity.valueMissing && !input.validity.badInput) {
		input.setCustomValidity('');
	}

	if (input.type === 'email' && input.validity.valueMissing && !input.validity.typeMismatch) {
		input.setCustomValidity('Molimo Vas unesite email adresu');
	} else if (
		input.type === 'email' &&
		!input.validity.valueMissing &&
		input.validity.typeMismatch
	) {
		input.setCustomValidity('Unesite ispravnu email adresu');
	} else if (
		input.type === 'email' &&
		!input.validity.valueMissing &&
		!input.validity.typeMismatch
	) {
		input.setCustomValidity('');
	}
	return input.reportValidity();
};

export const formValidation = form => {
	if (form.querySelectorAll('select')) {
		let selects = Array.of(form.querySelectorAll('select'))[0];

		selects.forEach(select => {
			if (select.name === 'sex' && select.validity.valueMissing) {
				select.setCustomValidity('Molimo Vas odaberite pol');
			} else if (select.name === 'sex' && !select.validity.valueMissing) {
				select.setCustomValidity('');
			}

			if (select.name === 'locationid' && select.validity.valueMissing) {
				select.setCustomValidity('Molimo Vas odaberite lokaciju');
			} else if (select.name === 'locationid' && !select.validity.valueMissing) {
				select.setCustomValidity('');
			}

			if (select.name === 'doctorid' && select.validity.valueMissing) {
				select.setCustomValidity('Molimo Vas odaberite doktora');
			} else if (select.name === 'doctorid' && !select.validity.valueMissing) {
				select.setCustomValidity('');
			}
		});
	}

	if (form.querySelectorAll('input')) {
		let password = form.querySelector('input[name="password"]');
		let confirmPassword = form.querySelector('input[name="confirmPassword"]');
		if (password && confirmPassword) {
			if (
				password.value !== '' &&
				confirmPassword.value !== '' &&
				password.value !== confirmPassword.value
			) {
				confirmPassword.setCustomValidity('Lozinke se ne poklapaju');
				return confirmPassword.reportValidity();
			}
		}

		let inputs = Array.of(form.querySelectorAll('input'))[0];
		inputs.forEach(input => {
			if (input.name === 'treatments' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas odaberite bar jedan od ponuđenih tretmana');
			} else if (input.name === 'treatments' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'name' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas unesite ime i prezime');
			} else if (input.name === 'name' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'firstname' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas unesite ime');
			} else if (input.name === 'firstname' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'lastname' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas unesite prezime');
			} else if (input.name === 'lastname' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'phone' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas unesite telefon');
			} else if (input.name === 'phone' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'password' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas unesite lozinku');
			} else if (input.name === 'password' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.name === 'confirmPassword' && input.validity.valueMissing) {
				input.setCustomValidity('Molimo Vas ponovite lozinku');
			} else if (input.name === 'confirmPassword' && !input.validity.valueMissing) {
				input.setCustomValidity('');
			}

			if (input.type === 'date' && input.validity.valueMissing && !input.validity.badInput) {
				input.setCustomValidity('Molimo Vas unesite datum');
			} else if (input.type === 'date' && input.validity.valueMissing && input.validity.badInput) {
				input.setCustomValidity('Unesite ispravan datum');
			} else if (
				input.type === 'date' &&
				!input.validity.valueMissing &&
				!input.validity.badInput
			) {
				input.setCustomValidity('');
			}

			if (input.type === 'email' && input.validity.valueMissing && !input.validity.typeMismatch) {
				input.setCustomValidity('Molimo Vas unesite email adresu');
			} else if (
				input.type === 'email' &&
				!input.validity.valueMissing &&
				input.validity.typeMismatch
			) {
				input.setCustomValidity('Unesite ispravnu email adresu');
			} else if (
				input.type === 'email' &&
				!input.validity.valueMissing &&
				!input.validity.typeMismatch
			) {
				input.setCustomValidity('');
			}
		});
	}

	return form.reportValidity();
};
