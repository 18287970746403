import React, { Component } from 'react';
import { fullDate, locationById } from '../utils/dataManipulation';

import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

class PhotoAlbum extends Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [],
		};
	}

	imageSort = () => {
		const id = this.props.stateAppointmentId;
		const allAppointments = this.props.appointments;
		const locationOptions = this.props.locationOptions;

		const appointmentsWithImages = [];
		allAppointments.forEach((appointment) => {
			if (appointment.imagepath && !id && this.props.profile !== 'doctor') {
				appointmentsWithImages.push(appointment);
			} else if (appointment.imagepath && appointment.appointmentid === id) {
				appointmentsWithImages.push(appointment);
			}
		});

		const images = appointmentsWithImages.map((appointment) => {
			return {
				src: appointment.imagepath,
				title: '',
				description: `[ Pacijent: ${appointment.patientfirstname} ${appointment.patientlastname} ]
						  [ Doktor: ${appointment.doctorfirstname} ${appointment.doctorlastname} ]
			              [ ${fullDate(appointment.date)} ]
			              [ ${locationById(appointment.locationid, locationOptions)} ]`,
			};
		});
		this.setState({ images: images });
	};

	componentDidMount() {
		this.setState(
			{
				images: [],
			},
			() => this.imageSort()
		);
	}

	componentDidUpdate(prevProps) {
		if (this.props.stateAppointmentId !== prevProps.stateAppointmentId) {
			this.setState(
				{
					images: [],
				},
				() => this.imageSort()
			);
		}
	}

	render() {
		const id = this.props.stateAppointmentId;
		const profile = this.props.profile;
		if (profile === 'doctor') {
			if (this.state.images.length !== 0 && id) {
				return <Lightbox images={this.state.images} thumbnailWidth='33%' thumbnailHeight='auto' />;
			} else if (this.state.images.length === 0 && id) {
				return <div className='no-info_div'>Nema slika za izabrani izveštaj!</div>;
			} else if (this.props.appointments.length === 0) {
				return null;
			} else {
				return <div className='no-info_div'>Izaberite izveštaj da biste videli slike.</div>;
			}
		} else if (profile === 'patient') {
			if (this.state.images.length !== 0) {
				return <Lightbox images={this.state.images} thumbnailWidth='33%' thumbnailHeight='auto' />;
			} else if (this.state.images.length === 0 && id) {
				return <div className='no-info_div'>Nema slika za izabrani izveštaj.</div>;
			} else if (this.props.appointments.length === 0) {
				return null;
			} else {
				return <div className='no-info_div'>Pacijent nema slike.</div>;
			}
		}
	}
}

export default PhotoAlbum;
