import React from 'react';
import { Link } from 'react-router-dom';
// import Image from '../ImgSrc/intmedius-beli.png';
import Image from '../ImgSrc/cropped-logo-veci-beli.png';

import { clearLocalStr } from '../utils';

const Navbar = (props) => {
	let path = props.props.location.pathname;
	return (
		<div id='navbar_container'>
			<div id='navbar_header'>
				<img id='navbar_header-img' src={Image} alt='' />
			</div>
			<div className='navbar_buttons' onClick={clearLocalStr}>
				<Link to='/dashboard/patients'>
					<div
						className={
							path.endsWith('dashboard') || path.includes('patients') ? 'nav_btn active' : 'nav_btn'
						}
						id='patients_btn'>
						<i className='fas fa-user-friends'></i>Pacijenti
					</div>
				</Link>
				<Link to='/dashboard/doctors'>
					<div className={path.includes('doctors') ? 'nav_btn active' : 'nav_btn'} id='doctors_btn'>
						<i className='fas fa-user-md'></i>Doktori
					</div>
				</Link>
				<div className='nav-info_div'>
					<div className={path.includes('doctor/') ? 'nav-info active' : 'nav-info'}>
						Profil doktora
					</div>
					<div className={path.includes('patient/') ? 'nav-info active' : 'nav-info'}>
						Profil pacijenta
					</div>
					<div className={path.includes('adddoctor') ? 'nav-info active' : 'nav-info'}>
						Novi doktor
					</div>
					<div className={path.includes('addpatient') ? 'nav-info active' : 'nav-info'}>
						Novi pacijent
					</div>
					<div className={path.includes('addreport') ? 'nav-info active' : 'nav-info'}>
						Novi izveštaj
					</div>
					<div
						className={
							path.includes('editreport') || path.includes('updateimages')
								? 'nav-info active'
								: 'nav-info'
						}>
						Izmena izveštaja
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
