import React, { Component } from 'react';
import { logout, host } from '../utils';
import { formDate, treatmentArr, fullDate, locationById } from '../utils/dataManipulation';
import { Button } from './FormComponents';
import loader from '../ImgSrc/loader.gif';

import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';

registerPlugin(
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginFileValidateType,
	FilePondPluginImageTransform
);

class ImageUpdate extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			appointment: {
				id: '',
				patientid: '',
				patientfirstname: '',
				patientlastname: '',
				doctorid: '',
				doctorfirstname: '',
				doctorlastname: '',
				locationid: '',
				location_title: '',
				date: '',
				anamnesis: '',
				obj_findings: '',
				diagnosis: '',
				therapy: '',
				treatments: [],
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			appointmentImages: [],
			files: [],
			locationOptions: [],
			isSecBtnDisabled: false,
			loading: true
		};
		this.pond = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		let aUrl = host + 'v1/appointments/' + this.props.match.params.id;
		let lUrl = host + 'v1/locations/';
		(async () => {
			let response = await fetch(aUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						appointment: {
							id: data[0].id,
							patientid: data[0].patientid,
							patientfirstname: data[0].patientfirstname,
							patientlastname: data[0].patientlastname,
							doctorid: data[0].doctorid,
							doctorfirstname: data[0].doctorfirstname,
							doctorlastname: data[0].doctorlastname,
							locationid: data[0].locationid,
							location_title: data[0].location_title,
							date: formDate(data[0].date),
							anamnesis: data[0].anamnesis,
							obj_findings: data[0].obj_findings,
							diagnosis: data[0].diagnosis,
							therapy: data[0].therapy,
							treatments: treatmentArr(data[0].treatments),
							updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
						},
						appointmentImages: data[0].images,
						loading: false
					});
				}
			}
		})();
		(async () => {
			let response = await fetch(lUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						locationOptions: data
					});
				}
			}
		})();
	};

	componentWillUnmount() {
		this._isMounted = false;
	};

	onSuccess = e => {
		let profile = localStorage.getItem('profile');
		if (profile === 'patient') {
			this.props.history.push('/dashboard/patient/' + this.state.appointment.patientid);
			localStorage.removeItem('profile');
		} else if (profile === 'doctor') {
			this.props.history.push('/dashboard/doctor/' + this.state.appointment.doctorid);
			localStorage.removeItem('profile');
		}
	};

	printImages = () => {
		const appointmentImages = this.state.appointmentImages;
		const appointment = this.state.appointment;

		const images = appointmentImages.map(image => {
			return {
				src: image,
				title: '',
				description: `[ Pacijent: ${appointment.patientfirstname} ${appointment.patientlastname} ]
						      [ Doktor: ${appointment.doctorfirstname} ${appointment.doctorlastname} ]
			                  [ ${fullDate(appointment.date)} ]
			                  [ ${locationById(appointment.locationid, this.state.locationOptions)} ]`
			};
		});

		if (images.length !== 0) {
			return <Lightbox images={images} thumbnailWidth='12.5%' thumbnailHeight='auto' />;
		} else if (images.length === 0) {
			return <div className='no-info_div'>Nema slika za izabrani izveštaj.</div>;
		}
	};

	render() {
		if (this.state.loading) {
			return (
				<div className='loader_div'>
					<img src={loader} alt='Učitava se...'></img>
				</div>
			);
		} else {
			return (
				<div className='image-update_div'>
					<div className='photo-album_div'>
						<div className='title-div'>
							<div className='title-div-item' title='Foto album'>
								<i className='far fa-images'></i>Foto album
							</div>
							<div className='title-div-item' title='Doktor'>
								<i className='fas fa-user-md'></i>
								{this.state.appointment.doctorfirstname} {this.state.appointment.doctorlastname}
							</div>
							<div className='title-div-item' title='Pacijent'>
								<i className='fas fa-user'></i>
								{this.state.appointment.patientfirstname} {this.state.appointment.patientlastname}
							</div>
							<div className='title-div-item' title='Datum'>
								<i className='far fa-calendar-plus'></i>
								{fullDate(this.state.appointment.date)}
							</div>
							<div className='title-div-item' title='Lokacija'>
								<i className='fas fa-map-marked-alt'></i>
								{locationById(this.state.appointment.locationid, this.state.locationOptions)}
							</div>
						</div>
						<div className='photo-album'>{this.printImages()}</div>
					</div>
					<div className='filepond_div'>
						<div className='form-container images'>
							<FilePond
								ref={ref => (this.pond = ref)}
								files={this.state.files}
								allowMultiple={true}
								allowRevert={false}
								maxFiles={10 - this.state.appointmentImages.length}
								dropOnPage={true}
								instantUpload={false}
								imagePreviewHeight='170'
								acceptedFileTypes={['image/jpeg']}
								labelIdle={
									10 - this.state.appointmentImages.length !== 0
										? '<span class="filepond--label-action">Dodajte nove slike.</span>'
										: 'Pregled već sadrži deset slika!'
								}
								labelTapToCancel=''
								labelTapToRetry=''
								onupdatefiles={fileItems => {
									this.setState({
										files: fileItems.map(fileItem => fileItem.file)
									});
									if (this.state.files.length > 0) {
										this.setState({
											isSecBtnDisabled: true
										});
									} else {
										this.setState({
											isSecBtnDisabled: false
										});
									}
								}}
								onprocessfile={(error, file) => {
									if (!error) {
										setTimeout(() => {
											this.pond.removeFile(file);
										}, 300);
									}
								}}
								server={{
									process: (fieldName, file, metadata, load, error, progress, abort) => {
										// fieldName is the name of the input field
										// file is the actual file object to send
										const formData = new FormData();
										formData.append('appointmentid', this.state.appointment.id);
										formData.append('patientid', this.state.appointment.patientid);
										formData.append(
											'updated_by',
											localStorage.getItem('user') ? localStorage.getItem('user') : ''
										);
										formData.append('images', file, file.name);

										const request = new XMLHttpRequest();
										request.open('POST', host + 'v1/images');
										// Should call the progress method to update the progress to 100% before calling load
										// Setting computable to false switches the loading indicator to infinite mode
										request.upload.onprogress = e => {
											progress(e.lengthComputable, e.loaded, e.total);
										};
										// Should call the load method when done and pass the returned server file id
										// this server file id is then used later on when reverting or restoring a file
										// so your server knows which file to return without exposing that info to the client
										request.onload = () => {
											if (request.status >= 200 && request.status < 300) {
												// the load method accepts either a string (id) or an object
												load(request.responseText);
												let newImagePath = JSON.parse(request.response).filesarr[0].location;
												this.setState(
													prevState => ({
														appointmentImages: [...prevState.appointmentImages, newImagePath]
													})
													// () => console.log(this.state.appointmentImages)
												);
											} else {
												// Can call the error method if something is wrong, should exit after
												error('oh no');
											}
										};
										request.send(formData);

										// Should expose an abort method so the request can be cancelled
										// return {
										// 	abort: () => {
										// 		// This function is entered if the user has tapped the cancel button
										// 		request.abort();
										// 		// Let FilePond know the request has been cancelled
										// 		abort();
										// 	}
										// };
									}
								}}
							/>
							<Button
								action={this.onSuccess}
								type={'secondary'}
								disabled={this.state.isSecBtnDisabled}
								title={
									this.state.isSecBtnDisabled
										? 'Uploadujte slike klikom na strelicu ili ih obrišite klikom na x'
										: 'Gotovo'
								}
							/>
						</div>
					</div>
				</div>
			);
		}
	};
}

export default ImageUpdate;
