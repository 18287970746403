import React, { Component } from 'react';
import Notification from '../components/notification';
import { logout } from '../utils';
import { inputValidation, formValidation } from '../utils/inputValidation';
import { SelectItem, Input, Button } from './FormComponents';
import { formDate } from '../utils/dataManipulation';
import loader from '../ImgSrc/loader.gif';

class EditPerson extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			updateDoctor: {
				id: '',
				firstname: '',
				lastname: '',
				birthdate: '',
				street: '',
				streetnum: '',
				city: '',
				country: '',
				email: '',
				phone: '',
				sex: '',
				title: '',
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			updatePatient: {
				id: '',
				firstname: '',
				lastname: '',
				birthdate: '',
				street: '',
				streetnum: '',
				city: '',
				country: '',
				email: '',
				phone: '',
				sex: '',
				profession: '',
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			sexOptions: [
				{ name: 'Muški', id: 1 },
				{ name: 'Ženski', id: 2 }
			],
			left: -400,
			notificationMsg: '',
			isButtonDisabled: false,
			loading: true
		};
		this.timeout = null;
		this.form = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		let url = this.props.url + '/' + this.props.match.params.id;
		(async () => {
			let response = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				if (this._isMounted) {
					let data = await response.json();
					if (this.props.doctorProfile) {
						this.setState({
							updateDoctor: {
								id: data[0].id,
								firstname: data[0].firstname,
								lastname: data[0].lastname,
								birthdate:
									!data[0].birthdate || data[0].birthdate === '0000-00-00'
										? ''
										: formDate(data[0].birthdate),
								street: data[0].street,
								streetnum: data[0].streetnum,
								city: data[0].city,
								country: data[0].country,
								email: data[0].email,
								phone: data[0].phone,
								sex: data[0].sex === 3 ? '' : data[0].sex,
								title: data[0].title === 'undefined' ? '' : data[0].title,
								updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
							},
							loading: false
						});
					} else if (this.props.patientProfile) {
						this.setState({
							updatePatient: {
								id: data[0].id,
								firstname: data[0].firstname,
								lastname: data[0].lastname,
								birthdate: formDate(data[0].birthdate),
								street: data[0].street,
								streetnum: data[0].streetnum,
								city: data[0].city,
								country: data[0].country,
								email: data[0].email,
								phone: data[0].phone,
								sex: data[0].sex,
								profession: data[0].profession,
								updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
							},
							loading: false
						});
					}
				}
			}
		})();
	};

	handleInput = e => {
		let value = e.target.value.trimStart();
		let name = e.target.name;
		if (this.props.doctorProfile) {
			this.setState(prevState => ({
				updateDoctor: {
					...prevState.updateDoctor,
					[name]: value
				},
				secBtnClass: 'sec-btn hdn'
			}));
		} else if (this.props.patientProfile) {
			this.setState(prevState => ({
				updatePatient: {
					...prevState.updatePatient,
					[name]: value
				},
				secBtnClass: 'sec-btn hdn'
			}));
		}
		inputValidation(e);
	};

	userData = () => {
		return this.props.doctorProfile ? this.state.updateDoctor : this.state.updatePatient;
	};

	handleFormSubmit = e => {
		e.preventDefault();
		if (formValidation(this.form.current)) {
			this.setState({
				isButtonDisabled: true
			});
			fetch(this.props.url, {
				method: 'PUT',
				body: JSON.stringify(this.userData()),
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			}).then(response => {
				response.json().then(data => {
					if (response.status === 200) {
						this.props.doctorProfile
							? this.props.history.push('/dashboard/doctor/' + this.state.updateDoctor.id)
							: this.props.history.push('/dashboard/patient/' + this.state.updatePatient.id);
					} else if (response.status === 400 || response.status === 500) {
						if (data.error.message.includes('ER_DUP_ENTRY')) {
							this.setState({
								notificationMsg: 'Osoba sa ovim podacima je već upisana!',
								isButtonDisabled: false
							});
						} else {
							this.setState({
								notificationMsg: 'Greška! Problem na serveru',
								isButtonDisabled: false
							});
						}
						this.onShowNotification();
					} else if (response.status === 404) {
						this.setState({
							notificationMsg: '404! Not Found!',
							isButtonDisabled: false
						});
						this.onShowNotification();
					} else if (response.status === 401) {
						logout();
						this.props.history.push('/signin');
						return;
					}
				});
			});
		}
	};

	onShowNotification = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.setState({ left: -400 }, () => {
				this.timeout = setTimeout(() => {
					this.showNotification();
				}, 500);
			});
		} else {
			this.showNotification();
		}
	};

	showNotification = () => {
		this.setState(
			{
				left: 20
			},
			() => {
				this.timeout = setTimeout(() => {
					this.setState({
						left: -400
					});
				}, 3000);
			}
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
		this._isMounted = false;
	};

	cancelSubmit = e => {
		e.preventDefault();
		this.props.doctorProfile
			? this.props.history.push('/dashboard/doctor/' + this.state.updateDoctor.id)
			: this.props.history.push('/dashboard/patient/' + this.state.updatePatient.id);
	};

	render() {
		if (this.state.loading) {
			return (
				<div className='loader_div'>
					<img src={loader} alt='Učitava se...'></img>
				</div>
			);
		} else {
			return (
				<div className='form_div'>
					<form ref={this.form} className='form-container' onSubmit={this.handleFormSubmit}>
						{this.props.doctorProfile ? (
							<div className='add-doctor naslov'>
								<i className='fas fa-user-md'></i> Izmeni podatke doktora
							</div>
						) : (
							<div className='add-patient naslov'>
								<i className='fas fa-user'></i> Izmeni podatke pacijenta
							</div>
						)}

						<Input
							type={'text'}
							title={'Ime'}
							name={'firstname'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.firstname
									: this.state.updatePatient.firstname
							}
							placeholder={'Unesite ime'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required
						/>
						<Input
							type={'text'}
							name={'lastname'}
							title={'Prezime'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.lastname
									: this.state.updatePatient.lastname
							}
							placeholder={'Unesite prezime'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required
						/>
						<Input
							type={'date'}
							name={'birthdate'}
							title={'Datum rođenja'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.birthdate
									: this.state.updatePatient.birthdate
							}
							placeholder={'Unesite datum rođenja'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required={this.props.doctorProfile ? false : true}
						/>
						<SelectItem
							title={'Pol'}
							name={'sex'}
							options={this.state.sexOptions}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.sex
									: this.state.updatePatient.sex
							}
							placeholder={'Odaberite pol'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required={this.props.doctorProfile ? false : true}
						/>
						<Input
							type={'text'}
							title={'Ulica'}
							name={'street'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.street
									: this.state.updatePatient.street
							}
							placeholder={'Unesite ulicu'}
							onChange={this.handleInput}
							disabled={this.state.isPrimBtnDisabled}
						/>
						<Input
							type={'text'}
							name={'streetnum'}
							title={'Broj'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.streetnum
									: this.state.updatePatient.streetnum
							}
							placeholder={'Unesite broj'}
							onChange={this.handleInput}
							disabled={this.state.isPrimBtnDisabled}
						/>
						<Input
							type={'text'}
							name={'city'}
							title={'Grad'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.city
									: this.state.updatePatient.city
							}
							placeholder={'Unesite grad'}
							onChange={this.handleInput}
							disabled={this.state.isPrimBtnDisabled}
						/>
						<Input
							type={'text'}
							name={'country'}
							title={'Država'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.country
									: this.state.updatePatient.country
							}
							placeholder={'Unesite državu'}
							onChange={this.handleInput}
							disabled={this.state.isPrimBtnDisabled}
						/>
						<Input
							type={'email'}
							name={'email'}
							title={'Email'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.email
									: this.state.updatePatient.email
							}
							placeholder={'Unesite email'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required
						/>
						<Input
							type={'text'}
							name={'phone'}
							title={'Telefon'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.phone
									: this.state.updatePatient.phone
							}
							placeholder={'Unesite telefon'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							disabled={this.state.isPrimBtnDisabled}
							required
						/>
						<Input
							type={'text'}
							name={this.props.doctorProfile ? 'title' : 'profession'}
							title={this.props.doctorProfile ? 'Zvanje' : 'Profesija'}
							value={
								this.props.doctorProfile
									? this.state.updateDoctor.title
									: this.state.updatePatient.profession
							}
							placeholder={this.props.doctorProfile ? 'Unesite zvanje' : 'Unesite profesiju'}
							onChange={this.handleInput}
							disabled={this.state.isPrimBtnDisabled}
						/>
						<div className='add-person-btn_div'>
							<div
								className={this.state.isButtonDisabled ? 'loader-btn_div' : 'loader-btn_div hdn'}>
								<img src={loader} alt='Učitava se...'></img>
							</div>
							<div className={this.state.isButtonDisabled ? 'btn_div hdn' : 'btn_div'}>
								<Button
									action={this.handleFormSubmit}
									type={'primary'}
									disabled={this.state.isButtonDisabled}
									title='Sačuvaj'
								/>
								<Button
									action={this.cancelSubmit}
									disabled={this.state.isButtonDisabled}
									title='Odustani'
								/>
							</div>
						</div>
					</form>
					<Notification left={this.state.left} notificationMsg={this.state.notificationMsg} />
				</div>
			);
		}
	};
}

export default EditPerson;
