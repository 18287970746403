import React from 'react';

export const fullDate = date => {
	if (date) {
		let d = new Date(date);
		return d.toDateString().substring(4);
	}
};

export const formDate = date => {
	let converted;
	if (date) {
		converted = new Date(date);
	} else {
		converted = new Date();
	}
	let y = converted.getFullYear();
	let m = converted.getMonth() + 1;
	let d = converted.getDate();
	return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
};

export const fullAddressById = (locationid, locationOptions) => {
	let result;
	locationOptions.forEach(location => {
		if (locationid === location.id) {
			result = location;
		}
	});
	return (
		<div className='print-address'>
			<span className='bold-span'>{result.location_name}</span>
			{!result.location_title_second_line || result.location_title_second_line === ' ' ? null : (
				<br />
			)}
			{!result.location_title_second_line || result.location_title_second_line === ' ' ? null : (
				<span className='bold-span'>{result.location_title_second_line}</span>
			)}
			<br />
			<span className='bold-span'>
				{result.street} {result.streetnum}, {result.city}
			</span>
			{!result.phone2 ? null : <br />}
			{!result.phone2 ? null : <span className='bold-span'>{result.phone2}</span>}
			<br />
			<span className='bold-span'>{result.phone1}</span>
		</div>
	);
};

export const locationById = (locationid, locationOptions) => {
	let result;
	locationOptions.forEach(location => {
		if (locationid === location.id) {
			result = location.location_title;
		}
	});
	return result;
};

export const treatmentList = treatments => {
	let arr = treatments.split(',');
	return arr.map((treatment, index) => {
		return (
			<div key={index} className='treatment-list-item'>
				<i className='fas fa-plus-circle'></i>
				{treatment}
			</div>
		);
	});
};

export const treatmentArr = treatments => {
	let arr = treatments.split(',');
	return arr;
};

export const birthdateOrTitle = (profile, person) => {
	if (profile === 'patient') {
		if (person.birthdate) {
			return fullDate(person.birthdate);
		}
	} else if (profile === 'doctor') {
		if (person.title === 'undefined') {
			return '';
		} else {
			return person.title;
		}
	}
};

export const personName = props => {
	if (props.profile === 'patient') {
		return (
			<React.Fragment>
				<i className='fas fa-user-md'></i>
				{props.appointment.doctorfirstname} {props.appointment.doctorlastname}
			</React.Fragment>
		);
	} else if (props.profile === 'doctor') {
		return (
			<React.Fragment>
				<i className='fas fa-user'></i>
				{props.appointment.patientfirstname} {props.appointment.patientlastname}
			</React.Fragment>
		);
	}
};

export const uniqueAppointmentsOf = appointments => {
	const arr = [];
	const map = new Map();
	appointments.forEach(function(appointment) {
		if (!map.has(appointment.appointmentid)) {
			map.set(appointment.appointmentid, true); // set any value to Map
			arr.push(appointment);
		}
	});
	return arr;
};
