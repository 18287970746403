import React, { Component } from 'react';
import Notification from '../components/notification';
import { Link } from 'react-router-dom';
import { host } from '../utils';
import { inputValidation, formValidation } from '../utils/inputValidation';
import { Input, Button } from './FormComponents';

class SignUp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newUser: {
				id: '',
				name: '',
				email: '',
				password: ''
			},
			beforeValidation: {
				password: '',
				confirmPassword: ''
			},
			left: -600,
			notificationMsg: '',
			isButtonDisabled: false,
			passInputType: 'password',
			confirmPassInputType: 'password'
		};
		this.timeout = null;
		this.form = React.createRef();
	}

	handleInput = e => {
		let value = e.target.value.trimStart();
		let name = e.target.name;
		this.setState(
			prevState => ({
				newUser: {
					...prevState.newUser,
					[name]: value
				}
			})
			// () => console.table(this.state.newUser)
		);
		inputValidation(e);
	};

	handlePassInput = e => {
		let target = e.target;
		let name = target.name;
		let value = target.value;
		this.setState(
			prevState => ({
				beforeValidation: {
					...prevState.beforeValidation,
					[name]: value
				}
			}),
			() => this.validatePass(target)
		);
	};

	validatePass = target => {
		if (target.name === 'password') {
			if (target.validity.valueMissing) {
				this.setState(
					prevState => ({
						newUser: {
							...prevState.newUser,
							password: ''
						}
					}),
					() => {
						target.setCustomValidity('Molimo Vas unesite lozinku');
						return target.reportValidity();
					}
				);
			} else {
				if (
					this.state.beforeValidation.password !== this.state.beforeValidation.confirmPassword &&
					this.state.beforeValidation.confirmPassword === ''
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: ''
							}
						}),
						() => {
							target.setCustomValidity('');
							return target.reportValidity();
						}
					);
				} else if (
					this.state.beforeValidation.password !== this.state.beforeValidation.confirmPassword &&
					this.state.beforeValidation.confirmPassword !== ''
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: ''
							}
						}),
						() => {
							target.setCustomValidity('Lozinke se ne poklapaju');
							return target.reportValidity();
						}
					);
				} else if (
					this.state.beforeValidation.password === this.state.beforeValidation.confirmPassword
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: this.state.beforeValidation.password
							}
						}),
						() => {
							target.setCustomValidity('');
							return target.reportValidity();
						}
					);
				}
			}
		} else if (target.name === 'confirmPassword') {
			if (target.validity.valueMissing) {
				this.setState(
					prevState => ({
						newUser: {
							...prevState.newUser,
							password: ''
						}
					}),
					() => {
						target.setCustomValidity('Molimo Vas ponovite lozinku');
						return target.reportValidity();
					}
				);
			} else {
				if (
					this.state.beforeValidation.password !== this.state.beforeValidation.confirmPassword &&
					this.state.beforeValidation.password === ''
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: ''
							}
						}),
						() => {
							target.setCustomValidity('');
							return target.reportValidity();
						}
					);
				} else if (
					this.state.beforeValidation.password !== this.state.beforeValidation.confirmPassword &&
					this.state.beforeValidation.password !== ''
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: ''
							}
						}),
						() => {
							target.setCustomValidity('Lozinke se ne poklapaju');
							return target.reportValidity();
						}
					);
				} else if (
					this.state.beforeValidation.password === this.state.beforeValidation.confirmPassword
				) {
					this.setState(
						prevState => ({
							newUser: {
								...prevState.newUser,
								password: this.state.beforeValidation.password
							}
						}),
						() => {
							target.setCustomValidity('');
							return target.reportValidity();
						}
					);
				}
			}
		}
	};

	toggleFirstPassVisibility = () => {
		if (this.state.passInputType === 'password') {
			this.setState({
				passInputType: 'text'
			});
		} else {
			this.setState({
				passInputType: 'password'
			});
		}
	};

	toggleSecondPassVisibility = () => {
		if (this.state.confirmPassInputType === 'password') {
			this.setState({
				confirmPassInputType: 'text'
			});
		} else {
			this.setState({
				confirmPassInputType: 'password'
			});
		}
	};

	handleFormSubmit = e => {
		e.preventDefault();
		if (formValidation(this.form.current)) {
			this.setState({
				isButtonDisabled: true
			});
			let userData = this.state.newUser;
			let url = host + 'v1/users/signup';
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(userData),
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json'
				}
			}).then(response => {
				response.json().then(data => {
					if (response.status === 200) {
						this.setState({
							notificationMsg: 'Uspešno ste se registrovali',
							isButtonDisabled: false
						});
						this.handleClearForm(e);
					} else if (response.status === 500) {
						if (data.error.message.includes('ER_DUP_ENTRY')) {
							this.setState({
								notificationMsg: 'Korisnik sa unetom email adresom je već registrovan',
								isButtonDisabled: false
							});
						} else {
							this.setState({
								notificationMsg: 'Greška! Problem na serveru',
								isButtonDisabled: false
							});
						}
					}
					this.onShowNotification();
				});
			});
		}
	};

	handleClearForm = e => {
		e.persist();
		this.setState({
			newUser: {
				id: '',
				name: '',
				email: '',
				password: ''
			},
			beforeValidation: {
				password: '',
				confirmPassword: ''
			}
		});
	};

	onShowNotification = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.setState({ left: -600 }, () => {
				this.timeout = setTimeout(() => {
					this.showNotification();
				}, 500);
			});
		} else {
			this.showNotification();
		}
	};

	showNotification = () => {
		this.setState(
			{
				left: 20
			},
			() => {
				this.timeout = setTimeout(() => {
					this.setState({
						left: -600
					});
				}, 3000);
			}
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	};

	render() {
		return (
			<div className='sign-in-up_div'>
				<form ref={this.form} className='sign-container' onSubmit={this.handleFormSubmit}>
					<div className='naslov'>
						<Link to='/signup' className='active-link'>
							<div>Registracija</div>
						</Link>
						<Link to='/signin' className='link'>
							<div>Prijava</div>
						</Link>
					</div>
					<div className='sign-form_div'>
						<Input
							type={'text'}
							title={'Ime i prezime'}
							name={'name'}
							value={this.state.newUser.name}
							placeholder={'Unesite ime i prezime'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							required
						/>
						<Input
							type={'email'}
							name={'email'}
							title={'Email'}
							autoComplete='new-email'
							value={this.state.newUser.email}
							placeholder={'Unesite email'}
							onChange={this.handleInput}
							onBlur={inputValidation}
							required
						/>
						<div className='signup-pass_div'>
							<div className='sign-pass_div'>
								<Input
									type={this.state.passInputType}
									name={'password'}
									title={'Lozinka'}
									autoComplete='new-password'
									value={this.state.beforeValidation.password}
									placeholder={'Unesite lozinku'}
									onChange={this.handlePassInput}
									onBlur={inputValidation}
									required
								/>
								<span onClick={this.toggleFirstPassVisibility}>
									{this.state.passInputType === 'password' ? (
										<i className='fas fa-eye' title='Prikaži lozinku'></i>
									) : (
										<i className='fas fa-eye-slash' title='Sakrij lozinku'></i>
									)}
								</span>
							</div>
							<div className='sign-pass_div'>
								<Input
									type={this.state.confirmPassInputType}
									name={'confirmPassword'}
									title={''}
									autoComplete='new-password'
									value={this.state.beforeValidation.confirmPassword}
									placeholder={'Potvrdi lozinku'}
									onChange={this.handlePassInput}
									onBlur={inputValidation}
									required
								/>
								<span onClick={this.toggleSecondPassVisibility}>
									{this.state.confirmPassInputType === 'password' ? (
										<i className='fas fa-eye' title='Prikaži lozinku'></i>
									) : (
										<i className='fas fa-eye-slash' title='Sakrij lozinku'></i>
									)}
								</span>
							</div>
						</div>
						<Button
							action={this.handleFormSubmit}
							type={'primary'}
							disabled={this.state.isButtonDisabled}
							title={this.state.isButtonDisabled ? 'Šalje se...' : 'Sačuvaj nalog'}
						/>
					</div>
				</form>
				<Notification left={this.state.left} notificationMsg={this.state.notificationMsg} />
			</div>
		);
	};
}

export default SignUp;
