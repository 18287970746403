import React, { Component } from 'react';
import Notification from '../components/notification';
import { logout, host } from '../utils';
import { formValidation } from '../utils/inputValidation';
import { formDate, treatmentArr } from '../utils/dataManipulation';
import { Input, TextArea, CheckBox, Button, SelectLocation } from './FormComponents';
import loader from '../ImgSrc/loader.gif';

class EditAppointmentForm extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			appointment: {
				id: '',
				patientid: '',
				doctorid: '',
				locationid: '',
				date: '',
				anamnesis: '',
				obj_findings: '',
				diagnosis: '',
				therapy: '',
				advice: '',
				treatments: [],
				updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
			},
			patientFullname: '',
			patientId: '',
			doctorFullname: '',
			doctorId: '',
			locationOptions: [],
			treatmentOptions: [],
			left: -500,
			notificationMsg: '',
			checkboxClass: 'checkbox',
			isPrimBtnDisabled: false,
			isInputDisabled: false,
			loading: true
		};
		this.timeout = null;
		this.form = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		let aUrl = host + 'v1/appointments/' + this.props.match.params.id;
		let lUrl = host + 'v1/locations/';
		(async () => {
			let response = await fetch(aUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						appointment: {
							id: data[0].id,
							patientid: data[0].patientid,
							doctorid: data[0].doctorid,
							locationid: data[0].locationid,
							date: formDate(data[0].date),
							anamnesis: data[0].anamnesis,
							obj_findings: data[0].obj_findings,
							diagnosis: data[0].diagnosis,
							therapy: data[0].therapy,
							advice: data[0].advice,
							treatments: treatmentArr(data[0].treatments),
							updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
						},
						patientFullname: data[0].patientfirstname + ' ' + data[0].patientlastname,
						patientId: data[0].patientid,
						doctorFullname: data[0].doctorfirstname + ' ' + data[0].doctorlastname,
						doctorId: data[0].doctorid,
						loading: false
					});
				}
			}
		})();
		(async () => {
			let response = await fetch(lUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						locationOptions: data,
						treatmentOptions: data[0].treatments
					});
				}
			}
		})();
	}

	handleInput = e => {
		let value = e.target.value;
		let name = e.target.name;
		this.setState(
			prevState => ({
				appointment: {
					...prevState.appointment,
					[name]: value
				}
			})
			// () => console.log(this.state.appointment)
		);
	};

	handleCheckBox = e => {
		const newSelection = e.target.value;
		let newSelectionArray;
		if (this.state.appointment.treatments.indexOf(newSelection) > -1) {
			newSelectionArray = this.state.appointment.treatments.filter(s => s !== newSelection);
		} else {
			newSelectionArray = [...this.state.appointment.treatments, newSelection];
		}

		if (newSelectionArray.length !== 0) {
			this.setState({
				checkboxClass: 'checkbox check',
				checkboxRequired: false
			});
		} else {
			this.setState({
				checkboxClass: 'checkbox',
				checkboxRequired: true
			});
		}

		this.setState(
			prevState => ({
				appointment: { ...prevState.appointment, treatments: newSelectionArray }
			})
			// () => console.log(this.state.appointment)
		);
	};

	handleFormSubmit = e => {
		e.preventDefault();
		if (formValidation(this.form.current)) {
			this.setState({
				isPrimBtnDisabled: true
			});
			let userData = this.state.appointment;
			let url = host + 'v1/appointments';
			fetch(url, {
				method: 'PUT',
				body: JSON.stringify(userData),
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			}).then(response => {
				response.json().then(data => {
					if (response.status === 200) {
						this.saveAndReturn();
					} else if (response.status === 400 || response.status === 500) {
						this.setState({
							notificationMsg: 'Greška! Problem na serveru',
							isPrimBtnDisabled: false
						});
						this.onShowNotification();
					} else if (response.status === 404) {
						this.setState({
							notificationMsg: '404! Not Found!',
							isButtonDisabled: false
						});
						this.onShowNotification();
					} else if (response.status === 401) {
						logout();
						this.props.history.push('/signin');
						return;
					}
				});
			});
		} else if (this.state.appointment.treatments.length === 0) {
			this.setState({
				checkboxClass: 'checkbox mustcheck'
			});
		}
	};

	onShowNotification = () => {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.setState({ left: -500 }, () => {
				this.timeout = setTimeout(() => {
					this.showNotification();
				}, 500);
			});
		} else {
			this.showNotification();
		}
	};

	showNotification = () => {
		this.setState(
			{
				left: 20
			},
			() => {
				this.timeout = setTimeout(() => {
					this.setState({
						left: -500
					});
				}, 3000);
			}
		);
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
		this._isMounted = false;
	}

	cancelSubmit = e => {
		e.preventDefault();
		if (localStorage.getItem('profile') === 'patient') {
			this.props.history.push('/dashboard/patient/' + this.state.appointment.patientid);
			localStorage.removeItem('profile');
		} else if (localStorage.getItem('profile') === 'doctor') {
			this.props.history.push('/dashboard/doctor/' + this.state.appointment.doctorid);
			localStorage.removeItem('profile');
		}
	};

	saveAndReturn = e => {
		if (localStorage.getItem('profile') === 'patient') {
			this.props.history.push('/dashboard/patient/' + this.state.appointment.patientid);
			localStorage.removeItem('profile');
		} else if (localStorage.getItem('profile') === 'doctor') {
			this.props.history.push('/dashboard/doctor/' + this.state.appointment.doctorid);
			localStorage.removeItem('profile');
		}
	};

	render() {
		if (this.state.loading) {
			return (
				<div className='loader_div'>
					<img src={loader} alt='Učitava se...'></img>
				</div>
			);
		} else {
			return (
				<div className='appointment_div'>
					<form ref={this.form} className='form-container report' onSubmit={this.handleFormSubmit}>
						<div className='add-appointment naslov'>Izmeni podatke pregleda</div>
						<Input
							type={'text'}
							title={'Pacijent'}
							name={'patientname'}
							value={this.state.patientFullname}
							disabled
							required
						/>
						<Input
							type={'text'}
							title={'Doktor'}
							name={'doctorname'}
							value={this.state.doctorFullname}
							disabled
							required
						/>
						<SelectLocation
							title={'Lokacija'}
							name={'locationid'}
							options={this.state.locationOptions}
							value={this.state.appointment.locationid}
							placeholder={'Odaberi lokaciju'}
							onChange={this.handleInput}
							required
							disabled={this.state.isInputDisabled}
						/>
						<Input
							type={'date'}
							name={'date'}
							title={'Datum pregleda'}
							value={this.state.appointment.date}
							onChange={this.handleInput}
							required
							disabled
						/>
						<CheckBox
							className={
								this.state.appointment.treatments.length !== 0
									? 'checkbox check'
									: this.state.checkboxClass
							}
							title={'Tretmani'}
							name={'treatments'}
							options={this.state.treatmentOptions}
							selectedOptions={this.state.appointment.treatments}
							onChange={this.handleCheckBox}
							required={this.state.appointment.treatments.length !== 0 ? false : true}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Anamneza'}
							rows={3}
							value={this.state.appointment.anamnesis}
							name={'anamnesis'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Objektivni nalaz'}
							rows={3}
							value={this.state.appointment.obj_findings}
							name={'obj_findings'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Dijagnoza'}
							rows={3}
							value={this.state.appointment.diagnosis}
							name={'diagnosis'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Terapija'}
							rows={3}
							value={this.state.appointment.therapy}
							name={'therapy'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<TextArea
							title={'Savet i kontrola'}
							rows={3}
							value={this.state.appointment.advice}
							name={'advice'}
							onChange={this.handleInput}
							disabled={this.state.isInputDisabled}
						/>
						<div className={this.state.isPrimBtnDisabled ? 'btn_div hdn' : 'btn_div'}>
							<Button
								action={this.handleFormSubmit}
								disabled={this.state.isPrimBtnDisabled}
								title='Sačuvaj'
							/>
							<Button
								action={this.cancelSubmit}
								disabled={this.state.isPrimBtnDisabled}
								title='Odustani'
							/>
						</div>
						<div className={this.state.isPrimBtnDisabled ? 'loader-btn_div' : 'loader-btn_div hdn'}>
							<img src={loader} alt='Učitava se...'></img>
						</div>
					</form>
					<Notification left={this.state.left} notificationMsg={this.state.notificationMsg} />
				</div>
			);
		}
	}
}

export default EditAppointmentForm;
