import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { logout, isLogin } from '../utils';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogin: isLogin()
		};
	}

	handleLogout = () => {
		logout();
		this.setState({
			isLogin: false
		});
	};

	render() {
		return (
			<div id='header'>
				<div id='login_area'>
					<div id='name-of-dr'>{localStorage.getItem('user')}</div>
					<div id='toolbar'>
						<Link to={'/'} className='logout-btn'>
							<button onClick={() => this.handleLogout()}>Odjavi se</button>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default Header;
