import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AppointmentCard from './AppointmentCard';
import AppoPhotoAlbum from './AppoPhotoAlbum';

import loader from '../ImgSrc/loader.gif';
import { clearLocalStr, logout, host } from '../utils';
import { uniqueAppointmentsOf } from '../utils/dataManipulation';

class AppointmentList extends Component {
	_isMounted = false;

	constructor() {
		super();
		this.state = {
			loading: true,
			noData: true,
			appointments: [],
			locationOptions: [],
			stateAppointmentId: null
		};
	}

	fetchData = () => {
		let aUrl = this.props.info.url + this.props.info.match.params.id + '/appointments';
		let lUrl = host + 'v1/locations/';
		(async () => {
			let response = await fetch(aUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.info.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					if (data.length > 0) {
						this.setState({
							loading: false,
							noData: false,
							appointments: data
						});
					} else if (data.length === 0) {
						this.setState({
							loading: false,
							noData: true
						});
					}
				}
			}
		})();
		(async () => {
			let response = await fetch(lUrl, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.info.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					this.setState({
						locationOptions: data
					});
				}
			}
		})();
	};

	toggleClass = id => {
		if (id === this.state.stateAppointmentId) {
			this.setState({
				stateAppointmentId: null
			});
			localStorage.removeItem('appo_id');
		} else {
			this.setState({
				stateAppointmentId: id
			});
			localStorage.setItem('appo_id', id);
		}
	};

	componentDidMount() {
		this._isMounted = true;
		this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.info.match.url !== prevProps.info.match.url) {
			this.setState(
				{
					loading: true,
					noData: true,
					appointments: [],
					locationOptions: [],
					stateAppointmentId: null
				},
				() => this.fetchData()
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	};

	render() {
		const appointments = uniqueAppointmentsOf(this.state.appointments);
		let personid = this.props.info.match.params.id;
		let info = this.props.info;
		return (
			<div>
				<div className='reports_div'>
					<div className='naslov'>
						<div>
							<i className='far fa-list-alt'></i>Lista izveštaja
						</div>
						<div className='naslov-btn' onClick={clearLocalStr}>
							{info.doctorProfile ? null : (
								<Link to={'/dashboard/addreport/' + personid} title='Novi izveštaj'>
									<i className='fas fa-file-medical'></i>
								</Link>
							)}
						</div>
					</div>
					{this.state.loading ? (
						<div className='loader_div'>
							<img src={loader} alt='Učitava se...'></img>
						</div>
					) : (
						<div>
							{appointments.length !== 0 ? (
								<div className='reports-body'>
									{appointments.map(appointment => (
										<AppointmentCard
											key={appointment.appointmentid}
											profile={this.props.info.doctorProfile ? 'doctor' : 'patient'}
											toggleClass={this.toggleClass}
											stateAppointmentId={this.state.stateAppointmentId}
											locationOptions={this.state.locationOptions}
											appointment={appointment}
										/>
									))}
								</div>
							) : (
								<div className='no-info_div'>
									{this.props.info.doctorProfile ? (
										<div className='doc'>
											<div>Doktor nema izveštaje.</div>
											<br />
											<Link to={'/dashboard/patients'} title='Lista pacijenata'>
												Lista pacijenata
											</Link>
										</div>
									) : (
										<div className='pat'>
											<div>Pacijent nema izveštaje.</div>
											<br />
											<Link
												to={'/dashboard/addreport/' + this.props.info.match.params.id}
												title='Novi izveštaj'>
												Novi izveštaj
											</Link>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
				{this.state.loading ? null : (
					<div className='photo-album_div'>
						<div className='naslov'>
							<div>
								<i className='far fa-images'></i>Foto album
							</div>
						</div>
						<div className='photo-album'>
							<AppoPhotoAlbum
								profile={this.props.info.doctorProfile ? 'doctor' : 'patient'}
								stateAppointmentId={this.state.stateAppointmentId}
								appointments={this.state.appointments}
								locationOptions={this.state.locationOptions}
							/>
						</div>
					</div>
				)}
			</div>
		);
	};
}

export default AppointmentList;
