import React from 'react';

import PersonalDetails from './PersonalDetails';
import AppointmentList from './AppointmentList';

const ProfileOfPerson = props => {
	return (
		<div className='profile_div '>
			<PersonalDetails info={props} />
			<AppointmentList info={props} />
		</div>
	);
};

export default ProfileOfPerson;
