import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { birthdateOrTitle } from '../utils/dataManipulation';
import { clearLocalStr, logout } from '../utils';

class PersonalDetails extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			person: [],
			loading: true
		};
	}

	fetchData = () => {
		let url = this.props.info.url + this.props.info.match.params.id;
		let doctorProfile = this.props.info.doctorProfile;
		let patientProfile = this.props.info.patientProfile;
		(async () => {
			let response = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.info.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				let personData = data[0];
				if (this._isMounted) {
					if (doctorProfile) {
						this.setState({
							person: {
								id: personData.id,
								firstname: personData.firstname,
								lastname: personData.lastname,
								birthdate:
									!personData.birthdate ||
									personData.birthdate.toString().substring(0, 10) === '0000-00-00'
										? ''
										: personData.birthdate,
								street: personData.street,
								streetnum: personData.streetnum,
								city: personData.city,
								country: personData.country,
								email: personData.email,
								phone: personData.phone,
								sex: personData.sex === 3 ? '' : personData.sex,
								title: personData.title === 'undefined' ? '' : personData.title,
								updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
							},
							loading: false
						});
					} else if (patientProfile) {
						this.setState({
							person: {
								id: personData.id,
								firstname: personData.firstname,
								lastname: personData.lastname,
								birthdate: personData.birthdate,
								street: personData.street,
								streetnum: personData.streetnum,
								city: personData.city,
								country: personData.country,
								email: personData.email,
								phone: personData.phone,
								sex: personData.sex,
								profession: personData.profession,
								updated_by: localStorage.getItem('user') ? localStorage.getItem('user') : ''
							},
							loading: false
						});
					}
				}
			}
		})();
	};

	componentDidMount() {
		this._isMounted = true;
		this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.info.match.url !== prevProps.info.match.url) {
			this.setState(
				{
					loading: true,
					person: []
				},
				() => this.fetchData()
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	};

	render() {
		let person = this.state.person;
		let info = this.props.info;
		let profile = info.doctorProfile ? 'doctor' : 'patient';
		return (
			<div className='personal-details-background'>
				{this.state.loading ? (
					<div
						className={
							info.doctorProfile
								? 'personal-details loading-personal doctor-profile'
								: 'personal-details loading-personal patient-profile'
						}
					/>
				) : (
					<div
						className={
							info.doctorProfile
								? 'personal-details doctor-profile'
								: 'personal-details patient-profile'
						}>
						<div className='personal-details-top'>
							<div className='personal-details-name'>
								{person.firstname} {person.lastname}
							</div>
							<div className='personal-details-btns'>
								{/* <div className='personal-details-btn' onClick={clearLocalStr}>
									{info.doctorProfile ? null : (
										<Link to={'/dashboard/addreport/' + person.id} title='Novi izveštaj'>
											<i className='fas fa-file-medical'></i>
										</Link>
									)}
								</div> */}
								<div className='personal-details-btn' onClick={clearLocalStr}>
									<Link
										to={'/dashboard/' + profile + '/' + person.id + '/edit'}
										title='Izmeni podatke'>
										<i className='fas fa-pencil-alt'></i>
									</Link>
								</div>
							</div>
						</div>
						<div className='personal-details-bottom'>
							<div
								className='personal-details-bottom-item'
								title={info.doctorProfile ? 'Zvanje' : 'Datum rođenja'}>
								{info.doctorProfile ? (
									<i className='fas fa-stethoscope'></i>
								) : (
									<i className='far fa-calendar-alt'></i>
								)}
								{birthdateOrTitle(profile, person)}
							</div>
							<div className='personal-details-bottom-item' title='Telefon'>
								<i className='fas fa-phone'></i> {person.phone}
							</div>
							<div className='personal-details-bottom-item' title='Email'>
								<i className='fas fa-at'></i> {person.email}
							</div>
							<div className='personal-details-bottom-item' title='Adresa'>
								<i className='fas fa-map-marked-alt'></i> {person.street} {person.streetnum}
								{person.city !== '' && person.street !== '' ? ', ' + person.city : person.city}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};
}

export default PersonalDetails;
