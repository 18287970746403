import React from 'react';

export const Input = props => {
	return (
		<div className='form-group'>
			<label htmlFor={props.name} className='form-label'>
				{props.title}
			</label>
			<input
				className='form-control'
				id={props.name}
				name={props.name}
				type={props.type}
				value={props.value}
				onChange={props.onChange}
				onBlur={props.onBlur}
				placeholder={props.placeholder}
				{...props}
			/>
		</div>
	);
};

export const SelectPerson = props => {
	return (
		<div className='form-group' title={props.title}>
			<label htmlFor={props.name}> {props.title} </label>
			<select
				id={props.name}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				onBlur={props.onBlur}
				required={props.required}
				disabled={props.disabled}
				className='form-control'>
				<option value='' disabled>
					{props.user ? props.user.firstname + ' ' + props.user.lastname : props.placeholder}
				</option>
				{props.options.map(option => {
					return (
						<option
							key={option.id}
							value={option.id}
							label={option.firstname + ' ' + option.lastname}>
							{option.firstname + ' ' + option.lastname}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export const SelectItem = props => {
	return (
		<div className='form-group' title={props.title}>
			<label htmlFor={props.name}> {props.title} </label>
			<select
				id={props.name}
				name={props.name}
				value={props.value}
				onBlur={props.onBlur}
				onChange={props.onChange}
				required={props.required}
				disabled={props.disabled}
				className='form-control'>
				<option value='' disabled>
					{props.placeholder}
				</option>
				{props.options.map(option => {
					return (
						<option key={option.id} value={option.id} label={option.name}>
							{option.name}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export const SelectLocation = props => {
	return (
		<div className='form-group' title={props.title}>
			<label htmlFor={props.name}> {props.title} </label>
			<select
				id={props.name}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				required={props.required}
				disabled={props.disabled}
				onBlur={props.onBlur}
				className='form-control'>
				<option value='' disabled>
					{props.placeholder}
				</option>
				{props.options.map(option => {
					return (
						<option key={option.id} value={option.id} label={option.location_title}>
							{option.location_title}
						</option>
					);
				})}
			</select>
		</div>
	);
};

export const TextArea = props => (
	<div className='form-group notes'>
		<label className='form-label'>{props.title}</label>
		<textarea
			className='form-control'
			name={props.name}
			rows={props.rows}
			cols={props.cols}
			value={props.value}
			onChange={props.onChange}
			disabled={props.disabled}
			placeholder={props.placeholder}
		/>
	</div>
);

export const CheckBox = props => {
	return (
		<div className='form-group notes'>
			<label htmlFor={props.name} className='form-label'>
				{props.title}
			</label>
			<div className={props.className}>
				{props.options.map((option, index) => {
					return (
						<label key={index} className='checkbox-inline'>
							<input
								className={props.name}
								name={props.name}
								onChange={props.onChange}
								onBlur={props.onBlur}
								required={props.required}
								value={option}
								disabled={props.disabled}
								checked={props.selectedOptions.indexOf(option) > -1}
								type='checkbox'
							/>
							{option}
						</label>
					);
				})}
			</div>
		</div>
	);
};

export const Button = props => {
	return (
		<div className='form-group'>
			<button
				style={props.style}
				className={props.type === 'primary' ? 'btn btn-primary' : 'btn btn-secondary'}
				onClick={props.action}
				disabled={props.disabled}>
				{props.title}
			</button>
		</div>
	);
};
