import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { host } from '../utils';

import AddAppointmentForm from '../Forms/AppointmentAddForm';
import EditAppointmentForm from '../Forms/AppointmentEditForm';
import ImageUpdate from '../Forms/AppoImageUpdate';

import AddPerson from '../Forms/PersonAddForm';
import EditPerson from '../Forms/PersonEditForm';

import ListOfPeople from '../Lists/ListOfPeople';
import ProfileOfPerson from '../Lists/ProfileOfPerson';

const Content = () => {
	return (
		<div id='content'>
			<Switch>
				<Route
					exact
					path='/dashboard'
					render={props => (
						<ListOfPeople {...props} patientProfile={true} url={host + 'v1/patients'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/patients'
					render={props => (
						<ListOfPeople {...props} patientProfile={true} url={host + 'v1/patients'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/doctors'
					render={props => (
						<ListOfPeople {...props} doctorProfile={true} url={host + 'v1/doctors'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/doctor/:id'
					render={props => (
						<ProfileOfPerson {...props} doctorProfile={true} url={host + 'v1/doctors/'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/patient/:id'
					render={props => (
						<ProfileOfPerson {...props} patientProfile={true} url={host + 'v1/patients/'} />
					)}
				/>

				<Route exact path='/dashboard/addreport/:id' component={AddAppointmentForm} />
				<Route exact path='/dashboard/editreport/:id' component={EditAppointmentForm} />
				<Route exact path='/dashboard/updateimages/:id' component={ImageUpdate} />

				<Route
					exact
					path='/dashboard/addpatient'
					render={props => (
						<AddPerson {...props} patientProfile={true} url={host + 'v1/patients'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/adddoctor'
					render={props => <AddPerson {...props} doctorProfile={true} url={host + 'v1/doctors'} />}
				/>

				<Route
					exact
					path='/dashboard/patient/:id/edit'
					render={props => (
						<EditPerson {...props} patientProfile={true} url={host + 'v1/patients'} />
					)}
				/>
				<Route
					exact
					path='/dashboard/doctor/:id/edit'
					render={props => <EditPerson {...props} doctorProfile={true} url={host + 'v1/doctors'} />}
				/>

				<Route
					render={props => (
						<ListOfPeople {...props} patientProfile={true} url={host + 'v1/patients'} />
					)}
				/>
			</Switch>
		</div>
	);
};

export default Content;
