import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Portal from './Portal';
import Report from './Report';
import { Link } from 'react-router-dom';
import { fullDate, treatmentList, personName, locationById } from '../utils/dataManipulation';

class AppointmentCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showWindowPortal: false
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (localStorage.getItem('appo_id') !== null) {
			this.props.toggleClass(parseInt(localStorage.getItem('appo_id')));
			this.scrollInView();
		}
		window.addEventListener('beforeunload', () => {
			this.closeWindowPortal();
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
	};

	toggleWindowPortal = () => {
		this.setState(state => ({
			...state,
			showWindowPortal: !state.showWindowPortal
		}));
	};

	closeWindowPortal = () => {
		this.setState({ showWindowPortal: false });
	};

	checkClassName = () => {
		this.props.toggleClass(this.props.appointment.appointmentid);
		this.scrollInView();
	};

	scrollInView = () => {
		setTimeout(() => {
			if (document.body.querySelector('.reports-body').querySelector('.active') !== null) {
				document.body
					.querySelector('.reports-body')
					.querySelector('.active')
					.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}, 100);
	};

	profileBtn = () => {
		if (this.props.profile === 'doctor') {
			return (
				<Link
					to={'/dashboard/patient/' + this.props.appointment.patientid}
					title='Profil pacijenta'>
					<i className='fas fa-user'></i>Profil pacijenta
				</Link>
			);
		} else if (this.props.profile === 'patient') {
			return null;
		}
	};

	editReport = () => {
		this.props.history.push('/dashboard/editreport/' + this.props.appointment.appointmentid);
		localStorage.setItem('profile', this.props.profile);
	};

	addImages = () => {
		this.props.history.push('/dashboard/updateimages/' + this.props.appointment.appointmentid);
		localStorage.setItem('profile', this.props.profile);
	};

	render() {
		let appointment = this.props.appointment;
		let stateAppointmentId = this.props.stateAppointmentId;
		let profile = this.props.profile;
		let locationOptions = this.props.locationOptions;
		return (
			<div
				className={
					stateAppointmentId === appointment.appointmentid
						? 'appointment-card active'
						: 'appointment-card'
				}>
				<div className='appointment-card-header' onClick={this.checkClassName}>
					<div className='appointment-card-header-item date-item' title='Datum pregleda'>
						<i className='far fa-calendar-plus'></i>
						{fullDate(appointment.date)}
					</div>
					<div
						className='appointment-card-header-item person-item'
						title={profile === 'doctor' ? 'Pacijent' : 'Doktor'}>
						{personName(this.props)}
					</div>
					<div className='appointment-card-header-item location-item' title='Lokacija'>
						<i className='fas fa-map-marked-alt'></i>
						{locationById(appointment.locationid, locationOptions)}
					</div>
					<div className='appointment-card-header-item treatment-list' title='Tretmani'>
						{treatmentList(appointment.treatments)}
					</div>
					<div className='appointment-card-header-btn'>
						<i className='fas fa-chevron-up'></i>
					</div>
				</div>
				<div className='appointment-card-body'>
					<div className='appointment-card-body-item'>
						<div className='appointment-card-body-item-label'>Anamneza:</div>
						<div className='appointment-card-body-item-content'>
							<pre>{appointment.anamnesis}</pre>
						</div>
					</div>
					<div className='appointment-card-body-item'>
						<div className='appointment-card-body-item-label'>Objektivni nalaz:</div>
						<div className='appointment-card-body-item-content'>
							<pre>{appointment.obj_findings}</pre>
						</div>
					</div>
					<div className='appointment-card-body-item'>
						<div className='appointment-card-body-item-label'>Dijagnoza:</div>
						<div className='appointment-card-body-item-content'>
							<pre>{appointment.diagnosis}</pre>
						</div>
					</div>
					<div className='appointment-card-body-item'>
						<div className='appointment-card-body-item-label'>Terapija:</div>
						<div className='appointment-card-body-item-content'>
							<pre>{appointment.therapy}</pre>
						</div>
					</div>
					<div className='appointment-card-body-item'>
						<div className='appointment-card-body-item-label'>Savet i kontrola:</div>
						<div className='appointment-card-body-item-content'>
							<pre>{appointment.advice}</pre>
						</div>
					</div>
					<div className='appointment-card-body-btns'>
						<div className='appointment-card-body-btns-item' id='profile-btn'>
							{this.profileBtn()}
						</div>
						<div className='appointment-card-body-btns-item'>
							<i
								className='fas fa-cloud-upload-alt'
								title='Dodaj slike'
								onClick={this.addImages}></i>
						</div>
						<div className='appointment-card-body-btns-item'>
							<i className='fas fa-pencil-alt' title='Izmeni pregled' onClick={this.editReport}></i>
						</div>
						<div className='appointment-card-body-btns-item'>
							<i className='fas fa-print' title='Štampaj' onClick={this.toggleWindowPortal}></i>
							{this.state.showWindowPortal && (
								<Portal closeWindowPortal={this.closeWindowPortal} appointment={appointment}>
									<Report locationOptions={locationOptions} appointment={appointment} />
								</Portal>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default withRouter(AppointmentCard);
