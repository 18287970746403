import React, { Component } from 'react';
import ListPersonItem from '../Lists/ListPersonItem';

import { Link } from 'react-router-dom';
import { logout } from '../utils';
import { Input } from '../Forms/FormComponents';
import loader from '../ImgSrc/loader.gif';

class ListOfPeople extends Component {
	_isMounted = false;

	constructor() {
		super();
		this.state = {
			loading: true,
			noData: true,
			people: [],
			searchQuery: ''
		};
	}

	fetchData = () => {
		let url =
			this.state.searchQuery === ''
				? this.props.url
				: this.props.url + '/search?search=' + this.state.searchQuery;
		(async () => {
			let response = await fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'content-Type': 'application/json',
					Authorization: 'Bearer ' + localStorage.getItem('token')
				}
			});
			if (response.status === 401) {
				logout();
				this.props.history.push('/signin');
				return;
			}
			if (response.status === 200) {
				let data = await response.json();
				if (this._isMounted) {
					if (data.length > 0) {
						this.setState({
							loading: false,
							noData: false,
							people: data
						});
					} else if (data.length === 0) {
						this.setState({
							loading: false,
							noData: true
						});
					}
				}
			}
		})();
	};

	handleSearch = e => {
		let value = e.target.value;
		this.setState(
			{
				searchQuery: value
			},
			() => this.fetchData()
		);
	};

	printList = () => {
		if (this.state.loading) {
			return (
				<div className='loader_div'>
					<img src={loader} alt='Učitava se...'></img>
				</div>
			);
		} else if (this.state.noData && !this.state.loading) {
			return this.state.searchQuery === '' ? (
				<div className='loader_div'>
					<p>Nema podataka</p>
				</div>
			) : this.props.doctorProfile ? (
				<div className='loader_div'>
					<div className='doc'>
						<div>Traženi doktor nije upisan</div>
						<br />
						<Link to={'/dashboard/adddoctor'} title='Dodaj doktora'>
							Dodaj doktora
						</Link>
					</div>
				</div>
			) : (
				<div className='loader_div'>
					<div className='pat'>
						<div>Traženi pacijent nije upisan</div>
						<br />
						<Link to={'/dashboard/addpatient'} title='Dodaj pacijenta'>
							Dodaj pacijenta
						</Link>
					</div>
				</div>
			);
		} else if (this.state.people.length) {
			return this.state.people.map(person => (
				<ListPersonItem
					key={person.id}
					profile={this.props.doctorProfile ? 'doctor' : 'patient'}
					person={person}
				/>
			));
		}
	};

	componentDidMount() {
		this._isMounted = true;
		this.fetchData();
	};

	componentDidUpdate(prevProps) {
		if (this.props.doctorProfile !== prevProps.doctorProfile) {
			this.setState(
				{
					people: [],
					loading: true,
					searchQuery: ''
				},
				() => this.fetchData()
			);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	};

	render() {
		return (
			<div className='grid_div'>
				<div className='grid-header'>
					<div id='search-input_div'>
						<Input
							type={'search'}
							value={this.state.searchQuery}
							placeholder={this.props.doctorProfile ? 'Pretraga doktora' : 'Pretraga pacijenata'}
							onChange={this.handleSearch}
						/>
					</div>
					<div
						id='table_header'
						className={this.props.doctorProfile ? 'doctor-list' : 'patient-list'}>
						<div className='table_header-col thin-col'>ID</div>
						<div className='table_header-col'>Prezime Ime</div>
						<div className='table_header-col'>
							{this.props.doctorProfile ? 'Zvanje' : 'Datum rođenja'}
						</div>
						<div className='table_header-col'>Broj telefona</div>
						<div className='table_header-col'>Email</div>
						<div className='table_header-col thin-col table_header-btn'>
							{this.props.doctorProfile ? (
								<Link to={'/dashboard/adddoctor'} title='Dodaj doktora'>
									<i className='fas fa-plus'></i>
									<i className='fas fa-user-md'></i>{' '}
								</Link>
							) : (
								<Link to={'/dashboard/addpatient'} title='Dodaj pacijenta'>
									<i className='fas fa-plus'></i>
									<i className='fas fa-user'></i>
								</Link>
							)}
						</div>
					</div>
				</div>
				<div className='grid-body'> {this.printList()}</div>
			</div>
		);
	};
}

export default ListOfPeople;
