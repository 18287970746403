import React, { Component } from "react";
import Notification from "../components/notification";
import { Link } from "react-router-dom";
import { login, host } from "../utils";
import { inputValidation, formValidation } from "../utils/inputValidation";
import { Input, Button } from "./FormComponents";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: {
        id: "",
        name: "",
        email: "",
        password: "",
      },
      left: -400,
      notificationMsg: "",
      isButtonDisabled: false,
      passInputType: "password",
    };
    this.timeout = null;
    this.form = React.createRef();
  }

  handleInput = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      (prevState) => ({
        newUser: {
          ...prevState.newUser,
          [name]: value,
        },
      })
      // () => console.log(this.state.newUser)
    );
    inputValidation(e);
  };

  togglePassVisibility = () => {
    if (this.state.passInputType === "password") {
      this.setState({
        passInputType: "text",
      });
    } else {
      this.setState({
        passInputType: "password",
      });
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (formValidation(this.form.current)) {
      this.setState({
        isButtonDisabled: true,
      });
      let userData = this.state.newUser;
      let url = host + "v1/users/signin";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          Accept: "application/json",
          "content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            this.setState({
              isButtonDisabled: false,
            });
            login(data.user.name, data.token, data.user.doctorid);
            this.props.history.push("/dashboard");
          } else if (response.status === 401) {
            this.setState({
              notificationMsg:
                "Niste uneli tačne podatke ili niste registrovani!",
              isButtonDisabled: false,
            });
            this.onShowNotification();
          } else if (response.status === 403) {
            this.setState({
              notificationMsg:
                "Korisnik mora biti odobren od strane administratora!",
              isButtonDisabled: false,
            });
            this.onShowNotification();
          }
        });
      });
    }
  };

  onShowNotification = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.setState({ left: -400 }, () => {
        this.timeout = setTimeout(() => {
          this.showNotification();
        }, 500);
      });
    } else {
      this.showNotification();
    }
  };

  showNotification = () => {
    this.setState(
      {
        left: 20,
      },
      () => {
        this.timeout = setTimeout(() => {
          this.setState({
            left: -400,
          });
        }, 3000);
      }
    );
  };

  render() {
    return (
      <div className="sign-in-up_div">
        <form
          ref={this.form}
          className="sign-container"
          onSubmit={this.handleFormSubmit}
        >
          <div className="naslov">
            <Link to="/signup" className="link">
              <div>Registracija</div>
            </Link>
            <Link to="/signin" className="active-link">
              <div>Prijava</div>
            </Link>
          </div>
          <div className="sign-form_div">
            <Input
              type={"email"}
              name={"email"}
              title={"Email"}
              value={this.state.newUser.email}
              placeholder={"Unesite email"}
              onChange={this.handleInput}
              onBlur={inputValidation}
              required
            />
            <div className="sign-pass_div">
              <Input
                type={this.state.passInputType}
                name={"password"}
                title={"Lozinka"}
                value={this.state.newUser.password}
                placeholder={"Unesite lozinku"}
                onChange={this.handleInput}
                onBlur={inputValidation}
                required
              />
              <span onClick={this.togglePassVisibility}>
                {this.state.passInputType === "password" ? (
                  <i className="fas fa-eye" title="Prikaži lozinku"></i>
                ) : (
                  <i className="fas fa-eye-slash" title="Sakrij lozinku"></i>
                )}
              </span>
            </div>

            <Button
              action={this.handleFormSubmit}
              type={"primary"}
              disabled={this.state.isButtonDisabled}
              title={this.state.isButtonDisabled ? "Logovanje..." : "Uloguj se"}
            />
          </div>
        </form>
        <Notification
          left={this.state.left}
          notificationMsg={this.state.notificationMsg}
        />
      </div>
    );
  }
}

export default SignIn;
