import React from 'react';
// import logo from '../ImgSrc/intmedius-crni_optimized.png';
import logo from '../ImgSrc/cropped-logo-veci.png';
import { fullDate, fullAddressById } from '../utils/dataManipulation';

const Report = (props) => {
	let locations = props.locationOptions;
	let appointment = props.appointment;

	return (
		<React.Fragment>
			<div className='print-layout'>
				<div className='print-header'>
					<div className='print-logo'>
						<img src={logo} alt='Diva Laser Centar' />
					</div>
					{fullAddressById(appointment.locationid, locations)}
				</div>
				<div className='print-content'>
					<div className='print-patient'>
						<div className='print-name'>
							<span className='bold-span'>Pacijent:</span>{' '}
							<span>
								{appointment.patientfirstname} {appointment.patientlastname}
							</span>
							<br />
							<span className='bold-span'>Datum rođenja:</span>{' '}
							<span>{fullDate(appointment.patientbirthdate)}</span>
							<br />
							{appointment.patientstreet === '' && appointment.patientcity === '' ? null : (
								<div>
									<span className='bold-span'>Adresa:</span>{' '}
									<span>
										{appointment.patientstreet} {appointment.patientstreetnum}
										{appointment.patientcity !== '' && appointment.patientstreet !== ''
											? ', ' + appointment.patientcity
											: appointment.patientcity}
									</span>
								</div>
							)}
						</div>
					</div>
					<div className='print-notes'>
						{appointment.anamnesis === '' ? null : (
							<div className='print-note'>
								<span className='bold-span'>Anamneza:</span>
								<br />
								<pre>{appointment.anamnesis}</pre>
							</div>
						)}
						{appointment.obj_findings === '' ? null : (
							<div className='print-note'>
								<span className='bold-span'>Objektivni nalaz:</span>
								<br />
								<pre>{appointment.obj_findings}</pre>
							</div>
						)}
						{appointment.diagnosis === '' ? null : (
							<div className='print-note'>
								<span className='bold-span'>Dijagnoza:</span>
								<br />
								<pre>{appointment.diagnosis}</pre>
							</div>
						)}
						{appointment.therapy === '' ? null : (
							<div className='print-note'>
								<span className='bold-span'>Terapija:</span>
								<br />
								<pre>{appointment.therapy}</pre>
							</div>
						)}
						{appointment.advice === '' ? null : (
							<div className='print-note'>
								<span className='bold-span'>Savet i kontrola:</span>
								<br />
								<pre>{appointment.advice}</pre>
							</div>
						)}
					</div>
					<div className='print-footer'>
						<div className='print-date'>
							<span className='bold-span'>Datum</span> <br />
							{fullDate(appointment.date)}, Beograd
						</div>
						<div className='print-doctor'>
							<span className='bold-span'>{appointment.doctortitle}</span> <br />
							{appointment.doctorfirstname} {appointment.doctorlastname}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default Report;
