import React from 'react';
import { birthdateOrTitle } from '../utils/dataManipulation';
import { withRouter } from 'react-router-dom';

const Person = props => {
	let person = props.person;

	const openProfile = () => {
		props.history.push('/dashboard/' + props.profile + '/' + person.id);
	};

	return (
		<div className='table_row table_row-div' onClick={openProfile}>
			<div className='table_row-col thin-col'>{person.id}</div>
			<div className='table_row-col'>
				{person.lastname} {person.firstname}
			</div>
			<div className='table_row-col'>{birthdateOrTitle(props.profile, props.person)}</div>
			<div className='table_row-col'>{person.phone}</div>
			<div className='table_row-col'>{person.email}</div>
			<div className='table_row-col thin-col table_row-btn'>
				<i className='fas fa-info-circle'></i>
			</div>
		</div>
	);
};

export default withRouter(Person);
