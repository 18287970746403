import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	background-color: #444;
	border-radius: 10px;
	color: white;
	padding: 16px;
	position: absolute;
	left: ${props => props.left}px;
	bottom: 30px;
	z-index: 999;
	transition: left 0.5s ease;
	opacity: 0.8;
`;

const Notification = props => {
	return <Container left={props.left}>{props.notificationMsg}</Container>;
};

export default Notification;
